import { get, cloneDeep } from 'lodash';

export default {
    props: {
        purchasePageCTA: {
            type: [Object, Boolean],
            default: false,
        },
        singleSlice: {
            type: Boolean,
            default: false,
        },
        settings: {
            type: Object,
            default: null,
        },
        information: {
            type: Array,
            default: null,
        },
        productCards: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            STORE_SLICE_NAME: 's-purchasing-cyber-protect-home-office',
        };
    },
    computed: {
        page() {
            return this.$store.state.slices.items?.[this.STORE_SLICE_NAME] || {};
        },
        content() {
            return this.page;
        },
        isSingle() {
            return this.singleSlice || this.content?.singleSlice || false;
        },
        isPriceForMonth() {
            return Boolean(this.content?.productCards?.isPriceForMonth);
        },
        pageTitle() {
            return this.title || this.content?.title || '';
        },
        pageLead() {
            return this.lead || this.content?.lead || '';
        },
        pageBodyText() {
            return this.bodyText || this.content?.bodyText || '';
        },
        pageProductCards() {
            return this.productCards || this.page?.productCards || {};
        },
        className() {
            return {
                ...this.basicClassName,
                's-purchasing-single': this.isSingle,
            };
        },
        message() {
            return (this.promo.isPromoActive && this.promo) || this.cta?.message || {};
        },
    },
    created() {
        this.setPromo();
    },
    mounted() {
        if (!this.content) return;
        this.products = cloneDeep(this.pageProductCards);
        if (this.information) {
            this.products.information = this.information;
        }
        if (this.promo.isPromoActive) {
            if (Number.isInteger(this.promo?.selectedPC)) {
                this.products.items.forEach((product, i) => {
                    this.products.items[i].pc.selected = this.promo.selectedPC;
                });
            }
            if (Number.isInteger(this.promo?.selectedLicense)) {
                this.products.selected = this.promo.selectedLicense;
            }
        }
        window.addEventListener('resize', this.resize);
        this.loaded = true;
    },
    methods: {
        setPromo() {
            this.cta = {
                ...this.productCTA({ isPurchase: true }),
                ...this.purchasePageCTA,
            };
            this.coupon = this.$route.query?.coupon || '';
            if (this.coupon && this.activeCoupon?.discount) {
                const license = this.activeCoupon.selectedLicense;
                const list = this.activeCoupon.cleverBridgeCartList || '';
                this.promo = {
                    ...this.activeCoupon,
                    isPurchase: true,
                    isPromoActive: true,
                    cleverBridgeCartList: list.split(',').map(Number),
                    selectedLicense: [1, 2, 3].includes(license) ? license - 1 : 0,
                    selectedPC: { 1: 0, 3: 1, 5: 2 }[this.activeCoupon?.selectedPC || 1],
                };
            } else {
                const promo = { ...this.cta.promo, isPurchase: true };
                const hasPromoPrices = this.checkPromo(promo);
                const isPromoActive = !this.cta.doNotApplyPromo && hasPromoPrices;
                this.promo = { ...promo, isPromoActive };
            }
        },
        licenseCart(license) {
            if (!license.selector) return license.cleverbridge_id;
            return `s${license.selector}:${license.cleverbridge_id}`;
        },
        proceedToCheckout(index) {
            const options = this.storeOptionsSURL({
                shopId: 882,
                segment: 'consumer',
                params: { scope: 'checkout' },
            });
            const license = this.findLicense(index);
            options.params.cart = this.licenseCart(license);
            if (license.coupon) options.params.coupon = license.coupon;
            if (this.hasCoupon(license)) options.params.coupon = this.coupon;
            this.redirectToCleverbridge(options);
            this.tryEcommerceSendEvent(index, 'add', 'Proceed to checkout');
        },
        cartID(index) {
            const product = this.products.items[index];
            const pc = product.pc.selected || 0;
            const cloud = product.cloud ? product.cloud.selected : null;
            return get(product.cleverBridgeCartID, [pc, cloud], product.cleverBridgeCartID[pc]);
        },
        classNameItem(name, item) {
            return {
                [`${name}-item`]: item,
                [`${name}-glyph`]: item.glyph,
                [`${name}-plus`]: item === '+',
                [`${name}-title`]: item.isTitle,
                [`${name}-text-blue`]: item.isTextBlue,
                [`${name}-glyph-blue`]: item.isGlyphBlue,
                [`${name}-bullet`]: !item.isTitle && !item.glyph && item !== '+',
            };
        },
        findLicense(index) {
            const cleverBridgeCartID = this.cartID(index);
            return this.licenseData({ cleverBridgeCartID, promo: this.promo });
        },
        hasCoupon(license) {
            if (!this.activeCoupon?.discount) return false;
            const list = this.activeCoupon.cleverBridgeCartList || '';
            return (!list || list.includes(license.cleverbridge_id));
        },
        productPriceLabel(index) {
            const license = this.findLicense(index);
            if (!license.discount_percentage) return '';
            return (this.promo?.label || '').replace('XX', Math.round(license.discount_percentage));
        },
        onChange(index, type, value) {
            const product = this.products.items[index][type];
            if (product.selected === value) return;
            this.tryEcommerceSendEvent(index, 'click', 'Remove from cart');
            product.selected = value;
            this.tryEcommerceSendEvent(index, 'click', 'Add to cart');
        },
        tryEcommerceSendEvent(index, type, eventAction) {
            const license = this.findLicense(index);
            const event = {
                eventAction,
                eventLabel: license.name || '',
                ecommerce: { type, license: license.cleverbridge_id },
            };
            this.ecommerceSendEvent({ event, license });
        },
    },
};
