import { render, staticRenderFns } from "./video-cards.vue?vue&type=template&id=c8a9e356&scoped=true"
import script from "./video-cards.vue?vue&type=script&lang=js"
export * from "./video-cards.vue?vue&type=script&lang=js"
import style0 from "./video-cards.vue?vue&type=style&index=0&id=c8a9e356&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8a9e356",
  null
  
)

export default component.exports